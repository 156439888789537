import { useState } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash.isequal'

/* Components */
import { Dropdown } from 'components/dropdown/index'
import Filters from 'components/filters/index'
import InputText from 'components/input/index'
import DropdownWithSubsections from 'components/dropdown-with-subsections/index'
import { entityStatus, utils } from '@decision-sciences/qontrol-common'

const { ENTITY_STATUS_OPTIONS } = entityStatus

const { getCommonFields } = utils.object

export const DEFAULT_FILTER_STATE = {
  searchTerm: '',
  active: '',
}

/**
 * Filter drawer for the Global Reports index page.
 * @param {Object} props
 * @param {Boolean} [props.loading] Loading flag
 * @param {Array} props.clients Available clients to filter by
 * @param {Array} props.teams Available teams to filter by
 * @param {Object} props.filters Current filters applied
 * @param {Function} props.onFiltersChange Callback to change the filters used in the parent component
 * @param {Function} props.onClear Callback to reset the filters to the initial value
 * @returns {React.ComponentElement}
 */
const GlobalReportsFilters = ({
  loading,
  clients,
  teams,
  filters,
  onFiltersChange,
  onClear,
}) => {
  const [localFilters, setLocalFilters] = useState({ ...filters })
  const allClientsSelected = localFilters.clients?.length === clients?.length
  const allTeamsSelected = localFilters.teams?.length === teams?.length

  const [initial, current] = getCommonFields(DEFAULT_FILTER_STATE, localFilters)

  return (
    <Filters
      loading={loading}
      onApply={() => onFiltersChange(localFilters)}
      onClear={() => {
        onClear()
        setLocalFilters({ ...filters })
      }}
      initialApplied={
        !isEqual(initial, current) ||
        localFilters.clients?.length ||
        localFilters.teams?.length
      }
    >
      <InputText
        searchBlue
        placeholder={'Search...'}
        value={localFilters.searchTerm}
        onChange={(value) =>
          setLocalFilters({ ...localFilters, searchTerm: value })
        }
      />

      <Dropdown
        label="Status"
        defaultOptionText="Status"
        options={ENTITY_STATUS_OPTIONS}
        defaultState={localFilters.active}
        deselectLabel="All Statuses"
        onChange={(active) =>
          setLocalFilters({
            ...localFilters,
            active,
          })
        }
        className="input-wrapper--uppercase"
      />

      <DropdownWithSubsections
        label="Clients"
        defaultOptionText="All"
        options={clients?.map((client) => ({
          label: client.name,
          value: client._id,
        }))}
        selectedItems={localFilters.clients}
        selectAllOptions={{
          label: 'All Clients',
          allSelected: allClientsSelected,
          onCheck: () => {
            const newFilters = { ...localFilters }
            newFilters.clients = allClientsSelected
              ? []
              : clients?.map((_id) => _id)

            setLocalFilters(newFilters)
          },
        }}
        onChange={(clients) => setLocalFilters({ ...localFilters, clients })}
        className="input-wrapper--uppercase"
      />

      <DropdownWithSubsections
        label="Teams"
        defaultOptionText="All"
        options={teams?.map((team) => ({ label: team.name, value: team._id }))}
        selectedItems={localFilters.teams}
        selectAllOptions={{
          label: 'All Teams',
          allSelected: allTeamsSelected,
          onCheck: () => {
            const newFilters = { ...localFilters }
            newFilters.teams = allTeamsSelected ? [] : teams?.map((_id) => _id)

            setLocalFilters(newFilters)
          },
        }}
        onChange={(teams) => setLocalFilters({ ...localFilters, teams })}
        className="input-wrapper--uppercase"
      />
    </Filters>
  )
}

GlobalReportsFilters.propTypes = {
  loading: PropTypes.bool,
  clients: PropTypes.array.isRequired,
  teams: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
}

export { GlobalReportsFilters }
