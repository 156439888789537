import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { redirect, useLocation, useParams } from 'react-router-dom'


/* Constants */
import { PERMISSIONS, PERMISSION_TYPES, useAccess } from 'hooks/access'
import { GlobalReportCreateEdit } from 'modules/global-reports/create-edit'

/**
 * Page wrapper for the Global Reports create/edit form.
 * @returns {React.ComponentElement}
 */
const GlobalReportsForm = () => {
  const { key } = useParams()

  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const duplicateFromId = queryParams.get('duplicate')

  const isCreate = key === 'new'

  const hasCreateAccess = useAccess({
    feature: PERMISSIONS.GLOBAL_REPORT_TEMPLATES,
    type: PERMISSION_TYPES.CREATE,
  })

  const hasEditAccess = useAccess({
    feature: PERMISSIONS.GLOBAL_REPORT_TEMPLATES,
    type: PERMISSION_TYPES.EDIT,
  })

  /* On mount or permission change, check if create mode is allowed */
  useEffect(() => {
    if (!hasCreateAccess && isCreate) {
      redirect('/unauthorized')
    }
  }, [hasCreateAccess, isCreate])

  /* On mount or permission change, check if edit mode is allowed */
  useEffect(() => {
    if (!hasEditAccess && !isCreate) {
      redirect('/unauthorized')
    }
  }, [hasEditAccess, isCreate])

  return (
    <section data-cy={`global-report-${isCreate ? 'create' : 'edit'}`}>
      <Helmet>
        <title>{`${
          isCreate ? 'Create' : 'Edit'
        } Global Report Template`}</title>
      </Helmet>

      <div className="heading" data-cy="page-heading">{`${
        isCreate ? 'Create' : 'Edit'
      } Global Report Template`}</div>

      <GlobalReportCreateEdit
        isCreate={isCreate}
        id={key}
        duplicateFromId={duplicateFromId}
      />
    </section>
  )
}

export { GlobalReportsForm }
