import { useMemo } from 'react'
import PropTypes from 'prop-types'

/* Components */
import DropdownWithSubsections from 'components/dropdown-with-subsections/index'
import InputText from 'components/input/index'
import { Dropdown } from 'components/dropdown/index'
import ListSelection from 'components/list-selection/index'

/* Utils & Constants */
import { utils, entityStatus } from '@decision-sciences/qontrol-common'

const { isEmpty } = utils.object
const { ENTITY_STATUS_OPTIONS } = entityStatus

/**
 * Form for manipulating the Global Report configuration.
 * @param {Object} props
 * @param {Object} props.globalReport Global Report being edited
 * @param {Object} props.templates Tableau templates
 * @param {Array} props.availableClients List of all clients
 * @param {Array} props.availableTeams List of all teams
 * @param {Array} props.availableUsers List of all users. They are filtered {@link filteredUsers} by the selected clients in the component
 * @param {Function} props.onChange Callback to propagate the changes to the parent
 * @param {Object} [props.errors = {}] Validation errors
 * @returns {React.ComponentElement}
 */
const GlobalReportForm = ({
  globalReport,
  templates = {},
  availableClients = [],
  availableTeams = [],
  availableUsers = [],
  onChange,
  errors = {},
}) => {
  const {
    name,
    active,
    template,
    clients,
    teams,
    users,
    allClientsSelected,
    allTeamsSelected,
  } = globalReport

  const filteredUsers = useMemo(() => {
    if (allClientsSelected) {
      return [...availableUsers]
    }
    if (!clients.length) {
      return []
    }
    return (
      availableUsers?.filter(({ clients: userClients }) =>
        userClients.some(({ clientId }) => clients.includes(clientId))
      ) || []
    )
  }, [
    JSON.stringify(availableUsers),
    JSON.stringify(clients),
    allClientsSelected,
  ])

  const handleAllClientsToggle = (isChecked) => {
    onChange({ clients: [], allClientsSelected: isChecked })
  }

  const handleTeamsSelection = (selectedTeams) => {
    const allSelected = selectedTeams.length === availableTeams.length
    onChange({
      teams: allSelected ? [] : selectedTeams,
      allTeamsSelected: allSelected,
    })
  }

  return (
    <form className="form">
      <section className="form__section">
        <div className="align-row form__section__body">
          <div className="align-column gap-16 form__section__body__half-width-section">
            <InputText
              label="Name"
              error={errors.name}
              placeholder="Enter Template Name"
              value={name}
              onChange={(name) => onChange({ name })}
              className="input-wrapper--uppercase"
            />
            <Dropdown
              error={errors.template}
              label="Tableau Template"
              defaultOptionText="Select Tableau Template"
              defaultState={template}
              options={Object.keys(templates).map((name) => ({
                label: name,
                value: templates[name],
              }))}
              onChange={(template) => onChange({ template })}
              className="input-wrapper--uppercase"
            />
          </div>
          <div className="align-column gap-16 form__section__body__half-width-section right-side">
            <Dropdown
              label="Status"
              error={errors.status}
              defaultOptionText="Status"
              options={ENTITY_STATUS_OPTIONS}
              defaultState={active}
              onChange={(active) => onChange({ active })}
              className="input-wrapper--uppercase"
            />
            <DropdownWithSubsections
              label="Clients"
              error={errors.clients}
              defaultOptionText="Select Clients"
              selectedItems={clients.map((el) => el._id || el)}
              options={availableClients.map(({ _id, name, businessUnits }) => ({
                value: _id,
                label: name,
                subsections:
                  businessUnits.length > 0
                    ? businessUnits.map(({ name, _id }) => ({
                        value: _id,
                        label: name,
                      }))
                    : undefined,
              }))}
              selectAllOptions={{
                label: 'All',
                allSelected: allClientsSelected,
                onCheck: handleAllClientsToggle,
              }}
              onChange={(clientIds) => {
                onChange({
                  allClientsSelected: false,
                  clients: clientIds,
                })
              }}
              className="input-wrapper--uppercase"
            />
          </div>
        </div>
      </section>
      <section className="form__section">
        <ListSelection
          initialOpenState={!isEmpty(teams) || allTeamsSelected}
          availableItems={availableTeams.map((team) => ({
            name: team.name,
            _id: team._id,
          }))}
          selectedItems={(allTeamsSelected ? availableTeams : teams).map(
            (team) => ({
              _id: team._id,
              name: team.name,
            })
          )}
          setSelectedItems={(teams) => handleTeamsSelection(teams)}
          idKey="_id"
          displayKey="name"
          title="Assign to Teams"
          availableListName="Teams"
          selectedListName="Selected Teams"
          expandButton={
            <div className="align-row center expand-button">+ Add Teams</div>
          }
        />
        {errors.teams && <div className="error">{errors.teams}</div>}
      </section>
      <section className="form__section">
        <ListSelection
          initialOpenState={!isEmpty(users)}
          availableItems={filteredUsers.map((user) => ({
            name: `${user.firstName} ${user.lastName}`,
            _id: user._id,
          }))}
          selectedItems={users.map((user) => ({
            name: user.name ? user.name : `${user.firstName} ${user.lastName}`,
            _id: user._id,
          }))}
          setSelectedItems={(users) => onChange({ users })}
          idKey="_id"
          displayKey="name"
          title="Assign to Users"
          availableListName="Users"
          selectedListName="Selected Users"
          expandButton={
            <div className="align-row center expand-button">+ Add Users</div>
          }
        />
        {errors.users && <div className="error">{errors.users}</div>}
      </section>
    </form>
  )
}

GlobalReportForm.propTypes = {
  globalReport: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  templates: PropTypes.object,
  availableClients: PropTypes.array,
  availableTeams: PropTypes.array,
  availableUsers: PropTypes.array,
  errors: PropTypes.object,
}

export { GlobalReportForm }

