import { useEffect, useState } from 'react'

/* Components */
import Table from 'components/table/beta'
import { ReactComponent as DeleteIcon } from 'assets/icon_delete.svg'
import Icon from 'components/icon/index'
import { GlobalReportsFilters, DEFAULT_FILTER_STATE } from 'modules/global-reports/components/filters'
import Loader from 'components/loader/index'

/* Utils & Constants */
import { PERMISSIONS, PERMISSION_TYPES, useAccess } from 'hooks/access'
import { deleteConfirmation } from 'modules/global-reports/utils'
import { format } from 'date-fns'
import { getTooltipList } from 'components/utils/tooltip'
import cx from 'classnames'

/* Hooks */
import { useNavigate } from 'react-router-dom'
import { useIntermediaryStates } from 'hooks/intermediary-states'

/* Store & Actions */
import { useStore } from 'store'
import { filterGlobalReports } from 'modules/global-reports/actions'
import { getTeams } from 'modules/teams/actions'

/**
 * Component which renders the table containing the Global Reports.
 * @returns {React.ComponentElement}
 */
const GlobalReportsModule = () => {
  const navigate = useNavigate()

  const {
    state: { globalReports, teams, companies },
    dispatch,
  } = useStore()

  const { list } = globalReports

  const { setLoadingFor, getLoadingFor } = useIntermediaryStates({})

  const [filters, setFilters] = useState({
    ...DEFAULT_FILTER_STATE,
  })

  const hasCreateAccess = useAccess({
    feature: PERMISSIONS.GLOBAL_REPORT_TEMPLATES,
    type: PERMISSION_TYPES.CREATE,
  })

  const hasEditAccess = useAccess({
    feature: PERMISSIONS.GLOBAL_REPORT_TEMPLATES,
    type: PERMISSION_TYPES.EDIT,
  })

  const hasDeleteAccess = useAccess({
    feature: PERMISSIONS.GLOBAL_REPORT_TEMPLATES,
    type: PERMISSION_TYPES.DELETE,
  })

  /** On component mount, fetch available teams if necessary */
  useEffect(() => {
    if (!teams.list) {
      getTeams(dispatch, null)
    }
  }, [])

  /** On component mount or {@link filters} change, filter the global reports */
  useEffect(() => {
    // If the list was already fetched, do not show loading spinner to not have a lot of content shift/flash
    setLoadingFor(LOADING.LIST, !list)
    filterGlobalReports(dispatch, filters).finally(() =>
      setLoadingFor(LOADING.LIST, false)
    )
  }, [filters])

  const handleDelete = (id) => {
    setLoadingFor(LOADING.DELETE, true)
    deleteConfirmation(hasDeleteAccess, id, dispatch).finally(() =>
      setLoadingFor(LOADING.DELETE, false)
    )
  }

  const columns = [
    {
      id: 'edit',
      header: ' ',
      cell: (cell) => {
        if (!hasEditAccess) {
          return null
        }
        const { _id } = cell.row.original
        return (
          <div className="table__actions align-center">
            <div
              className={'table__edit'}
              onClick={() => {
                navigate(`/global-reports/${_id}`)
              }}
            />
          </div>
        )
      },
      size: 40,
      maxSize: 70,
    },
    {
      id: 'duplicate',
      header: ' ',
      cell: (cell) => {
        if (!hasCreateAccess) {
          return null
        }
        const { _id } = cell.row.original
        return (
          <div className="table__actions align-center">
            <div
              className="table__duplicate"
              onClick={() => {
                const params = new URLSearchParams({ duplicate: _id })
                navigate(`/global-reports/new?${params.toString()}`)
              }}
            />
          </div>
        )
      },
      size: 40,
      maxSize: 70,
    },
    {
      header: 'Name',
      accessorKey: 'name',
      width: 100,
      textAlign: 'left',
      cellTextAlign: 'left',
      sortingFn: 'name',
    },
    {
      header: 'Associated Teams',
      accessorFn: (row) =>
        row.allTeamsSelected ? 'All' : row.teams ? row.teams.length : 0,
      tooltip: (row) => {
        const { allTeamsSelected, teams } = row
        return !allTeamsSelected && teams?.length > 0
          ? getTooltipList(
              'Teams',
              row.teams.map((item) => item.name)
            )
          : null
      },
      textAlign: 'left',
      cellTextAlign: 'left',
      sortType: 'number',
    },
    {
      header: 'Associated Users',
      accessorFn: (row) => (row.users ? row.users.length : 0),
      tooltip: (row) => {
        const { users } = row
        return users?.length > 0
          ? getTooltipList(
              'Users',
              row.users.map((item) => `${item.firstName} ${item.lastName}`)
            )
          : null
      },
      size: 100,
      textAlign: 'left',
      cellTextAlign: 'left',
      sortType: 'number',
    },
    {
      header: 'Last Modified',
      accessorFn: (row) => {
        return row.updatedAt
          ? format(new Date(row.updatedAt), 'MM/dd/yyyy')
          : '-'
      },
      size: 100,
      textAlign: 'left',
      cellTextAlign: 'left',
    },
    {
      id: 'delete',
      header: '',
      cell: (cell) => {
        if (!hasDeleteAccess) {
          return null
        }
        return (
          <Icon
            className={cx('cursor--pointer', {
              'svg--disabled': getLoadingFor(LOADING.DELETE),
            })}
            dataCy="global-report-delete"
          >
            <DeleteIcon
              onClick={() =>
                !getLoadingFor(LOADING.DELETE) &&
                handleDelete(cell.row.original._id)
              }
              className="fill-red"
            />
          </Icon>
        )
      },
      size: 40,
      maxSize: 70,
      show: hasDeleteAccess,
    },
  ]

  return (
    <div className="global-reports-index">
      {getLoadingFor(LOADING.LIST) ? (
        <Loader />
      ) : (
        <Table
          showPagination={true}
          autoSort
          columns={columns}
          data={list || []}
          paginationValues={[5, 10, 20, 50, 100]}
          loading={getLoadingFor(LOADING.LIST)}
          totalRows={list?.length || 0}
          showSearchInput={true}
        />
      )}
      <GlobalReportsFilters
        loading={getLoadingFor(LOADING.LIST)}
        filters={filters}
        clients={companies?.list || []}
        teams={teams?.list || []}
        onFiltersChange={(filters) => setFilters(filters)}
        onClear={() => setFilters({ ...DEFAULT_FILTER_STATE })}
      />
    </div>
  )
}

const LOADING = {
  LIST: 'LIST',
  DELETE: 'DELETE',
}

export { GlobalReportsModule }
