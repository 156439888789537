import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, redirect } from 'react-router-dom'

/* Components */
import Button from 'components/button/index'
import { GlobalReportsModule } from 'modules/global-reports/index'

/* Constants */
import { PERMISSIONS, PERMISSION_TYPES, useAccess } from 'hooks/access'

/**
 * Page wrapper for global reports index component
 * @returns {React.ReactElement}
 */
const GlobalReports = () => {
  const navigate = useNavigate()

  const hasCreateAccess = useAccess({
    feature: PERMISSIONS.GLOBAL_REPORT_TEMPLATES,
    type: PERMISSION_TYPES.CREATE,
  })

  const hasEditAccess = useAccess({
    feature: PERMISSIONS.GLOBAL_REPORT_TEMPLATES,
    type: PERMISSION_TYPES.EDIT,
  })

  /* On mount or permission change, if the user does not have access, redirect to unauthorized page */
  useEffect(() => {
    if (!hasCreateAccess && !hasEditAccess) {
      redirect('/unauthorized')
    }
  }, [hasEditAccess])

  return (
    <section data-cy="global-report-page">
      <Helmet>
        <title>{'Global Report Templates'}</title>
      </Helmet>
      <div className="heading" data-cy="page-heading">
        {'Global Report Templates'}
        {hasCreateAccess && (
          <div className="heading__buttons">
            <Button
              value={'Create Global Report Template'}
              onClick={() => {
                navigate('/global-reports/new')
              }}
            />
          </div>
        )}
      </div>
      <GlobalReportsModule />
    </section>
  )
}

export { GlobalReports }
