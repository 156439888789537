import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

/* Components */
import Loader from 'components/loader/index'
import StickyFooter from 'components/sticky-footer/index'
import { GlobalReportForm } from 'modules/global-reports/components/global-report-form'

/* Utils & Constants */
import { FIELD_TYPES, validate } from 'components/validator/index'
import { NOT_FOUND_ROUTE } from 'routes'
import { deleteConfirmation } from 'modules/global-reports/utils'
import { PERMISSIONS, PERMISSION_TYPES, useAccess } from 'hooks/access'

/* Hooks */
import { useNavigate, redirect } from 'react-router-dom'
import { useIntermediaryStates } from 'hooks/intermediary-states'
import useLeaveConfirm from 'components/leave-confirm/index'

/* Store & Actions */
import { useStore } from 'store'
import {
  ACTIONS,
  getGlobalReport,
  getTableauTemplates,
  upsertGlobalReport,
} from 'modules/global-reports/actions'
import { getTeams } from 'modules/teams/actions'

import './styles.scss'

/**
 * Component used for creating or editing a Global Report
 * @param {Object} props
 * @param {String} props.id ObjectId of the Global Report or 'new'
 * @param {Boolean} props.isCreate Flag to signal if it's create mode
 * @param {String} [props.duplicateFromId = undefined] ObjectId of the Global Report that is being duplicated.
 * @returns {React.ComponentElement}
 */
const GlobalReportCreateEdit = ({ id, isCreate, duplicateFromId }) => {
  const navigate = useNavigate()

  const {
    state: { globalReports, teams, companies, users },
    dispatch,
  } = useStore()

  const { currentGlobalReport } = globalReports

  const hasDeleteAccess = useAccess({
    feature: PERMISSIONS.GLOBAL_REPORT_TEMPLATES,
    type: PERMISSION_TYPES.DELETE,
  })

  const [setDirty, LeaveConfirm, isDirty] = useLeaveConfirm()

  const { setLoadingFor, getLoadingFor } = useIntermediaryStates({})

  const [tableauTemplates, setTableauTemplates] = useState({})
  const [errors, setErrors] = useState({})

  /** On component mount, fetch teams if necessary */
  useEffect(() => {
    if (!teams?.list) {
      getTeams(dispatch, null)
    }
  }, [])

  /** On component mount or deps change, fetch necessary data */
  useEffect(() => {
    if (shouldFetchData()) {
      if (isCreate) {
        // If it's a duplicate action, get the global report that is being duplicated
        if (duplicateFromId) {
          setLoadingFor(LOADING.ENTITY, true)
          getGlobalReport(dispatch, duplicateFromId, true)
            .catch(() => {
              redirect(NOT_FOUND_ROUTE)
            })
            .finally(() => setLoadingFor(LOADING.ENTITY, false))
        } else {
          dispatch({
            type: ACTIONS.SET_EDITED_GOBAL_REPORT,
            currentGlobalReport: { ...NEW_GLOBAL_REPORT },
          })
        }
      } else {
        setLoadingFor(LOADING.ENTITY, true)
        getGlobalReport(dispatch, id)
          .catch(() => {
            redirect(NOT_FOUND_ROUTE)
          })
          .finally(() => setLoadingFor(LOADING.ENTITY, false))
      }
    }
  }, [id, isCreate, duplicateFromId])

  /** On component mount, fetch the templates from Tableau */
  useEffect(() => {
    getTableauTemplates()
      .then((data) => setTableauTemplates(data))
      .catch((error) => console.error(error))
  }, [])

  /** On component unmount reset the global report  */
  useEffect(() => {
    return () => {
      dispatch({
        type: ACTIONS.SET_EDITED_GOBAL_REPORT,
        currentGlobalReport: null,
      })
    }
  }, [])

  /**
   * Helper function to determine if data should be refreshed
   * @returns {Boolean}
   */
  const shouldFetchData = () => {
    if (!currentGlobalReport) {
      return true
    }
    const { _id } = currentGlobalReport
    return (isCreate && _id) || _id !== id
  }

  const handleFieldChange = (updatedGlobalReport) => {
    if (!isDirty) {
      setDirty(true)
    }
    /** Reset errors for changed fields */
    setErrors((previousErrors) => {
      const resetErrors = Object.keys(updatedGlobalReport).reduce(
        (acc, key) => ({ ...acc, [key]: null }),
        {}
      )
      return { ...previousErrors, ...resetErrors }
    })
    dispatch({
      type: ACTIONS.SET_EDITED_GOBAL_REPORT,
      currentGlobalReport: { ...currentGlobalReport, ...updatedGlobalReport },
    })
  }

  const handleSave = () => {
    const [isValid, validationErrors] = validate(ERROR_MAP, currentGlobalReport)
    if (!isValid) {
      setErrors(validationErrors)
      return
    }
    setLoadingFor(LOADING.ENTITY, true)
    setDirty(false)
    upsertGlobalReport(dispatch, currentGlobalReport, isCreate)
      .then(() => {
        setLoadingFor(LOADING.ENTITY, false)
        navigate('/global-reports')
      })
      .catch((error) => {
        setDirty(true)
        setLoadingFor(LOADING.ENTITY, false)
        console.error(error)
      })
  }

  const handleCancel = () => {
    navigate('/global-reports')
  }

  const handleDelete = () => {
    if (!hasDeleteAccess) {
      return
    }
    setLoadingFor(LOADING.DELETE, true)
    deleteConfirmation(hasDeleteAccess, id, dispatch)
      .then(() => {
        setLoadingFor(LOADING.DELETE, false)
        navigate('/global-reports')
      })
      .catch((e) => {
        setLoadingFor(LOADING.DELETE, false)
        if (e) {
          console.error(e)
        }
      })
      .finally(() => setLoadingFor(LOADING.DELETE, false))
  }

  return (
    <div className="global-reports-form">
      {getLoadingFor(LOADING.ENTITY) || !currentGlobalReport ? (
        <Loader />
      ) : (
        <GlobalReportForm
          globalReport={currentGlobalReport}
          onChange={handleFieldChange}
          templates={tableauTemplates}
          availableClients={companies?.list || []}
          availableTeams={teams?.list || []}
          availableUsers={users?.list || []}
          errors={errors}
        />
      )}

      <StickyFooter
        buttons={[
          {
            value: 'Save',
            disabled: getLoadingFor(LOADING.ENTITY),
            onClick: handleSave,
          },
          {
            value: 'Cancel',
            disabled: getLoadingFor(LOADING.ENTITY),
            onClick: handleCancel,
            secondaryGray: true,
          },
          {
            value: 'Delete',
            renderCondition: !isCreate && hasDeleteAccess,
            onClick: handleDelete,
            disabled:
              getLoadingFor(LOADING.ENTITY) || getLoadingFor(LOADING.DELETE),
            secondaryRed: true,
          },
        ]}
      />
      <LeaveConfirm />
    </div>
  )
}

const LOADING = {
  ENTITY: 'ENTITY',
  DELETE: 'DELETE',
}

const NEW_GLOBAL_REPORT = {
  id: null,
  active: true,
  name: '',
  template: null,
  clients: [],
  teams: [],
  users: [],
  allClientsSelected: true,
  allTeamsSelected: false,
}

const ERROR_MAP = {
  name: [FIELD_TYPES.REQUIRED],
  template: [FIELD_TYPES.REQUIRED],
  active: [FIELD_TYPES.REQUIRED, FIELD_TYPES.BOOLEAN],
}

GlobalReportCreateEdit.propTypes = {
  isCreate: PropTypes.bool,
  id: PropTypes.string.isRequired,
  duplicateFromId: PropTypes.string,
}

export { GlobalReportCreateEdit }
